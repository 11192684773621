<template>
  <v-card>
    <v-card-title>
      <div class="pt-3 pl-2">{{ $t("newUser") }}</div>
    </v-card-title>
    <v-card-text>
      <v-divider></v-divider>
      <user-inputs
        :actions="true"
        @save="createUser($event)"
        @cancel="cancelCreation()"
      ></user-inputs>
    </v-card-text>
  </v-card>
</template>

<script>
import UserInputs from "../components/admin/UserInputs.vue";
export default {
  methods: {
    cancelCreation() {
      this.$router
        .push({
          name: "Admin users",
          query: { study: this.currentStudy.name }
        })
        .catch(() => {});
    },
    async createUser(user) {
      const userExist = await this.userAlreadyExist(user);
      if (userExist)
        this.setError("User already exist. Please use another email");
      else {
        await this.drivers.userDriver
          .save(this.currentStudy, user)
          .catch(err => {
            Array.isArray(err)
              ? err.map(error => this.setError(error))
              : this.setError(err);
          });
        this.$router.push({
          name: "Admin users",
          query: { study: this.currentStudy.name }
        });
      }
    },
    async userAlreadyExist(user) {
      const users = await this.drivers.userDriver.getAll(this.currentStudy);
      return !!users.find(u => u.email == user.email);
    }
  },
  components: { UserInputs },
  i18n: {
    messages: {
      en: {
        newUser: "Create a new user on study"
      },
      fr: {
        newUser: "Créer un nouvel utilisateur sur l'étude"
      }
    }
  }
};
</script>
